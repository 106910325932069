const enhanceLinkHrefWithRedirectUrl = (linkHref: string, redirectUrl: string) => {
    const encodedRedirectUrl = encodeURIComponent(redirectUrl);

    return `${linkHref}?redirect_url=${encodedRedirectUrl}`;
};

export const getLoginUrl = (linkHref: string = '/login') => {
    return enhanceLinkHrefWithRedirectUrl(linkHref, window.location.href);
};

export const getRegisterUrl = (linkHref: string) => {
    return enhanceLinkHrefWithRedirectUrl(linkHref, `${window.location.origin}/account`);
};

export const getLogoutUrl = (linkHref: string) => {
    const redirectUrl = window.location.pathname.startsWith('/account')
        ? undefined
        : window.location.href;

    return redirectUrl ? enhanceLinkHrefWithRedirectUrl(linkHref, redirectUrl) : linkHref;
};
