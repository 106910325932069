import { type MouseEvent, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import loadable from '@loadable/component';
import classNames from 'classnames';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { CountryCode, Tenant, TenantEnum } from '@jsmdg/tenancy';
import {
    ClickEventLabel,
    EventCategory,
    GA4EventName,
    GA4FeatureCategory,
    trackAnalyticsEvent,
} from '@jsmdg/tracking';
import { Image, Link, LinkVariant, TrustedShopsLogoSrc } from '@jsmdg/yoshi';
import { type HeaderContext } from '../../types';
import { CallButton } from './CallButton';
import { PaybackLink } from './PaybackLink';
import { SimpleTextLink } from './SimpleTextLink';
import styles from './TopHeader.module.scss';

const LoadableContactModal = loadable(async () => import('./ContactModal'));

const messages = defineMessages({
    trustedShop: {
        defaultMessage: 'Trustedshops Bewertungen für mydays.de',
    },
});
const TopHeader = (): JSX.Element => {
    const [isContactModalOpen, setIsContactModalOpen] = useState(false);
    const { redemptionInformation, tenant, tenantConfig }: HeaderContext = useFragmentContext();
    const intl = useIntl();

    const tenantInstance = new Tenant(tenant);
    const isDEStore = tenantInstance.belongsTo(CountryCode.Germany);

    const trustedShopLink =
        'https://www.trustedshops.de/bewertung/info_XF19E52605958CC17000B9656BC0326F7.html';

    const onTrustedShopClick = async (e: MouseEvent): Promise<void> => {
        e.preventDefault();
        await window.yieldToMainThread();
        trackAnalyticsEvent({
            category: EventCategory.Navigation,
            action: 'Header',
            label: ClickEventLabel.TRUSTED_SHOP,
            eventData: {
                eventName: GA4EventName.ClickNavigationItem,
                feature_category: GA4FeatureCategory.HeaderNavigation,
                click_element: 'Trused Shop',
                click_text: 'Trused Shop',
                click_url: trustedShopLink,
            },
        });

        window.location.assign(trustedShopLink);
    };

    return (
        <>
            <div className="d-none d-md-flex justify-content-between w-100 flex-grow-1 pt-md-1x">
                <div className={classNames('d-flex align-items-center', styles.topHeader)}>
                    {tenant === TenantEnum.MD_DE && (
                        <Link
                            href={trustedShopLink}
                            className="mr-3x"
                            variant={LinkVariant.GreyQuiet}
                            onClick={async (e: MouseEvent) => onTrustedShopClick(e)}
                        >
                            <span className="screenReaderOnly">
                                {intl.formatMessage(messages.trustedShop)}
                            </span>
                            <Image
                                src={TrustedShopsLogoSrc}
                                alt=""
                                lazyLoad={false}
                                width={24}
                                height={24}
                                fetchpriority="low"
                                className="align-middle"
                            />
                        </Link>
                    )}
                    {isDEStore && <PaybackLink />}
                    <SimpleTextLink
                        eventLabel="FAQ"
                        eventName="FAQ"
                        url={tenantConfig.urls.faq}
                        label="FAQ"
                    />

                    {!redemptionInformation?.isEnabled && (
                        <div>
                            <SimpleTextLink
                                eventLabel="Jobs"
                                eventName="Jobs"
                                url={tenantConfig.urls.career}
                                label="Jobs"
                                target="_blank"
                            />
                            <SimpleTextLink
                                eventLabel="Magazin"
                                eventName="Magazin"
                                url={tenantConfig.urls.magazine}
                                label="Magazin"
                                target="_blank"
                            />
                            <SimpleTextLink
                                eventLabel="Experience Partner"
                                eventName="Experience Partner"
                                url={tenantConfig.partnerPortalUrl}
                                label="Erlebnispartner werden"
                            />
                        </div>
                    )}
                </div>
                <div className={classNames('d-flex align-items-center', styles.topHeader)}>
                    <SimpleTextLink
                        eventLabel="Contact"
                        eventName="Contact"
                        url={tenantConfig.urls.contact}
                        label="Kontakt"
                    />
                    <CallButton
                        number={tenantConfig.contactInfo.phone.label}
                        onClick={() => setIsContactModalOpen(true)}
                    />
                </div>
            </div>
            {isContactModalOpen && (
                <LoadableContactModal
                    onRequestClose={() => setIsContactModalOpen(false)}
                    phoneLabel={tenantConfig.contactInfo.phone.label}
                    phoneNumber={tenantConfig.contactInfo.phone.number}
                    phoneCountry={Tenant[tenant].getCountryCode()}
                    isOpen={isContactModalOpen}
                />
            )}
        </>
    );
};

export { TopHeader };
