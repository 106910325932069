import React, { useState } from 'react';
import loadable from '@loadable/component';
import classNames from 'classnames';

import { getHideWelcomePopup } from '@jsmdg/browser-storage';
import { useFragmentContext } from '@jsmdg/react-fragment-scripts/dist/fragment/useFragmentContext';
import { Breakpoint, useBreakpoint } from '@jsmdg/yoshi';
import { getLoginUrl } from '../../../helper/customerAccountUrlUtils';
import { useActivateListItemWithDelay } from '../../../hooks/useActivateListItemWithDelay';
import { useRedemptionContext } from '../../../hooks/useRedemptionContext';
import { type HeaderContext } from '../../../types';
import { CustomerAccountButton } from '../CustomerAccountButton/CustomerAccountButton';
import styles from './CustomerAccountFlyout.module.scss';

type CustomerAccountFlyoutProps = {
    readonly setShowFlyout: (showFlyout: boolean) => void;
};

const LoadableLoggedOutCustomerFlyout = loadable(
    async () => import('../LoggedOutCustomerFlyout/LoggedOutCustomerFlyout'),
);

const LoadableLoggedInCustomerFlyout = loadable(
    async () => import('../LoggedInCustomerFlyout/LoggedInCustomerFlyout'),
);

const LoadableVoucherModal = loadable(async () => import('../../VoucherModal/VoucherModal'));

const LoadableCustomerWelcomeFlyout = loadable(
    async () => import('../CustomerWelcomeFlyout/CustomerWelcomeFlyout'),
);

const CustomerAccountFlyout: React.FC<CustomerAccountFlyoutProps> = ({ setShowFlyout }) => {
    const { isLoggedIn, isNewCustomer } = useFragmentContext<HeaderContext>();
    const { redemptionInformation } = useRedemptionContext();

    const isNotSmallScreen = useBreakpoint(Breakpoint.XS);
    const isTabletScreen = useBreakpoint(Breakpoint.SM);
    const isDesktop = useBreakpoint(Breakpoint.MD);

    const [isWelcomeFlyoutDisabled, setIsWelcomeFlyoutDisabled] = useState(!isNewCustomer);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(isLoggedIn);

    const { activeItemIndex, resetActiveItemIndexWithDelay, setActiveItemIndexWithDelay } =
        useActivateListItemWithDelay();
    const showCustomerAccountFlyout = activeItemIndex !== -1 && !redemptionInformation?.isEnabled;
    const showCustomerWelcomeFlyout =
        !isWelcomeFlyoutDisabled && !getHideWelcomePopup().get() && isUserLoggedIn;
    const showVoucherModal =
        activeItemIndex !== -1 && redemptionInformation?.isEnabled && !isTabletScreen;

    const handleCustomerAccountButtonClick = (show: boolean): void => {
        if (!redemptionInformation?.isEnabled && (isUserLoggedIn || !isNotSmallScreen)) {
            window.location.assign(isUserLoggedIn ? '/account' : getLoginUrl());
            return;
        }

        if (show) {
            setActiveItemIndexWithDelay(1);
        } else {
            resetActiveItemIndexWithDelay();
        }
    };

    const handleOnClick = (): void => {
        if (isDesktop) handleCustomerAccountButtonClick(true);

        if (isUserLoggedIn) {
            window.location.href = '/account';
        } else {
            setShowFlyout(true);
            setActiveItemIndexWithDelay(1);
        }

        if (redemptionInformation?.isEnabled) setActiveItemIndexWithDelay(1);
    };

    const handleMouseEnter = (): void => {
        if (redemptionInformation?.isEnabled || !isTabletScreen) return;
        setActiveItemIndexWithDelay(1);
    };

    const handleMouseLeave = (): void => {
        if (redemptionInformation?.isEnabled || !isTabletScreen) return;
        resetActiveItemIndexWithDelay();
    };

    const renderFlyoutContent = (): JSX.Element | null => {
        if (!showCustomerAccountFlyout) return null;

        if (!isLoggedIn) {
            return (
                <LoadableLoggedOutCustomerFlyout
                    onMouseEnter={() => setActiveItemIndexWithDelay(1)}
                    onRequestClose={() => resetActiveItemIndexWithDelay()}
                    isTablet={isTabletScreen}
                />
            );
        }

        if (isDesktop) {
            return (
                <LoadableLoggedInCustomerFlyout
                    onMouseEnter={() => setActiveItemIndexWithDelay(1)}
                    onRequestClose={() => resetActiveItemIndexWithDelay()}
                    onClickLogout={() => setIsUserLoggedIn(false)}
                />
            );
        } else {
            return null;
        }
    };

    return (
        <div className="position-relative ml-sm-1-5x">
            <CustomerAccountButton
                highlighted={showCustomerWelcomeFlyout}
                layoutClassName={classNames(styles.icon)}
                onClick={handleOnClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            />
            {renderFlyoutContent()}
            {showVoucherModal && (
                <LoadableVoucherModal
                    mouseEnter={() => setActiveItemIndexWithDelay(1)}
                    onRequestClose={() => resetActiveItemIndexWithDelay()}
                />
            )}

            {showCustomerWelcomeFlyout && (
                <LoadableCustomerWelcomeFlyout
                    onRequestClose={() => {
                        setIsWelcomeFlyoutDisabled(true);
                        getHideWelcomePopup().set('true');
                    }}
                />
            )}
        </div>
    );
};

export default CustomerAccountFlyout;
