import axios from 'axios';

import { createFragment } from '@jsmdg/react-fragment-scripts/fragment';
import { type HeaderRootProps } from '../shared/types';
import { Header } from './components/Header';
import { addVoucherCode } from './services/addVoucherCode';
import { createCartButtonClickedEvent } from './services/createCartButtonClickedEvent';
import { fetchCart } from './services/fetchCart';
import { removeVoucherCode } from './services/removeVoucherCode';
import { getTenantConfig } from './tenantConfig';
import { type HeaderContext } from './types';

async function initDependencies({ baseUrl }: { baseUrl?: string }): Promise<void> {
    axios.defaults.baseURL = baseUrl;
    // eslint-disable-next-line no-promise-executor-return
    await new Promise<void>(resolve => resolve());
}

const { Fragment, init } = createFragment<HeaderRootProps, HeaderContext>(
    Header,
    ({ locale, tenant, rootComponentProps }) => {
        return {
            locale,
            tenant,
            appEnv: rootComponentProps.appEnv,
            fetchCart,
            createCartButtonClickedEvent,
            searchTerm: rootComponentProps.searchTerm,
            showBreadcrumbs: rootComponentProps.showBreadcrumbs,
            breadcrumbs: rootComponentProps.breadcrumbs,
            topNavigationItems: rootComponentProps.topNavigationItems,
            topRedemptionNavigationItems: rootComponentProps.topRedemptionNavigationItems,
            type: rootComponentProps.type,
            featureFlagsVariation: rootComponentProps.featureFlagsVariation,
            cartUrl: rootComponentProps.cartUrl,
            checkoutUrl: rootComponentProps.checkoutUrl,
            showBetaFeatures: rootComponentProps.showBetaFeatures,
            headerMode: rootComponentProps.headerMode,
            isLoggedIn: rootComponentProps.isLoggedIn,
            initialWishlist: rootComponentProps.initialWishlist,
            algoliaConfig: rootComponentProps.algoliaConfig,
            tenantConfig: {
                ...getTenantConfig(tenant),
                ...rootComponentProps.sharedData,
            },
            isMydays: rootComponentProps.isMydays,
            isNewCustomer: rootComponentProps.isNewCustomer,
            customerInfo: rootComponentProps.customerInfo,
            addVoucherCode,
            removeVoucherCode,
            redemptionInformation: rootComponentProps.redemptionInformation,
            campaignCreatives: rootComponentProps.campaignCreatives,
            voucherOverviewEnabled: rootComponentProps.voucherOverviewEnabled,
        };
    },
    initDependencies,
);

export { Fragment, init };
