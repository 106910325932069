import { type ReactNode, useEffect, useRef } from 'react';
import classnames from 'classnames';

import { useIntersection } from '@jsmdg/yoshi';
import styles from './CheckoutSteps.module.scss';

type CheckoutStepsProps = {
    readonly children: ReactNode;
};

const CheckoutStepsWrapper = ({ children }: CheckoutStepsProps): JSX.Element => {
    const wasSticky = useRef(false);
    const [isIntersecting, setTargetElement] = useIntersection({ threshold: 1 });

    useEffect(() => {
        // Set wasSticky to true after the page is loaded
        wasSticky.current = true;
    }, []);

    const isSticky = !isIntersecting && wasSticky.current;

    return (
        <div ref={node => node && setTargetElement(node)} className="w-100">
            <div
                className={classnames(
                    'w-100 d-flex justify-content-between',
                    styles.checkoutSteps,
                    { [styles.fixedTop]: isSticky },
                )}
            >
                {children}
            </div>
        </div>
    );
};

export { CheckoutStepsWrapper };
