const isInternalLink = (url: string): boolean => {
    return (
        !url?.includes('http') ||
        url?.includes('jochen-schweizer') ||
        url?.includes('mydays') ||
        url?.includes('jsmd-group')
    );
};

export { isInternalLink };
