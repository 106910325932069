import { useEffect, useState } from 'react';
import { useIsSSR } from 'react-aria';
import { defineMessages, useIntl } from 'react-intl';
import loadable from '@loadable/component';
import classNames from 'classnames';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { Tenant } from '@jsmdg/tenancy';
import { trackCategoryTreeDisplayName } from '@jsmdg/tracking';
import { HeadsetIcon, Link as LinkButton, LinkVariant, RenderType } from '@jsmdg/yoshi';
import { type HeaderContext } from '../../types';
import { Logo } from '../Logo';
import styles from './RedeemHeader.module.scss';

const LoadableContactModal = loadable(async () => import('../TopHeader/ContactModal'));

const messages = defineMessages({
    helpButton: {
        defaultMessage: 'Hilfe?',
    },
});

const RedeemHeader = (): JSX.Element => {
    const intl = useIntl();
    const [isContactModalOpen, setIsContactModalOpen] = useState(false);
    const { breadcrumbs, tenant, tenantConfig, type } = useFragmentContext<HeaderContext>();
    const isSSR = useIsSSR();

    useEffect(() => {
        if (!isSSR) {
            trackCategoryTreeDisplayName(breadcrumbs, type);
        }
    }, [breadcrumbs, isSSR, type]);

    return (
        <>
            <header className={classNames(styles.redeemHeader)}>
                <div className="py-2x d-flex container align-items-center justify-content-between">
                    <Logo isOnMdBreakpoint={false} href="/" />
                    <div className="pl-2x">
                        <LinkButton
                            as={RenderType.Button}
                            variant={LinkVariant.Brand}
                            className={classNames('fw-regular align-middle', styles.helpButtonText)}
                            onClick={() => setIsContactModalOpen(true)}
                            iconLeft={<HeadsetIcon />}
                        >
                            {intl.formatMessage(messages.helpButton)}
                        </LinkButton>
                    </div>
                </div>
            </header>
            {isContactModalOpen && (
                <LoadableContactModal
                    onRequestClose={() => setIsContactModalOpen(false)}
                    phoneLabel={tenantConfig.contactInfo.phone.label}
                    phoneNumber={tenantConfig.contactInfo.phone.number}
                    phoneCountry={Tenant[tenant].getCountryCode()}
                    isOpen={isContactModalOpen}
                />
            )}
        </>
    );
};

export { RedeemHeader };
