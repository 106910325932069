import { type KeyboardEvent, type MouseEvent, useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { trackWishlistButtonClick, trackWishlistItemCount } from '@jsmdg/tracking';
import { useWishlistSubscribe } from '@jsmdg/wishlist-library';
import {
    Badge,
    BadgeColor,
    BadgeSize,
    Button,
    ButtonColor,
    ButtonShape,
    ButtonSize,
    ButtonVariant,
    HeartIcon,
    RenderType,
} from '@jsmdg/yoshi';
import { type HeaderContext } from '../../types';
import styles from '../common/common_styles.module.scss';

const messages = defineMessages({
    description: {
        defaultMessage: 'Zur Merkliste',
    },
    itemsInWishlist: {
        defaultMessage: 'Artikel in Merkliste',
    },
});

type WishlistButtonProps = {
    readonly layoutClassName?: string;
};

const WishlistButton = ({ layoutClassName }: WishlistButtonProps): JSX.Element => {
    const intl = useIntl();

    const { initialWishlist } = useFragmentContext<HeaderContext>();

    const { wishlist } = useWishlistSubscribe(initialWishlist);

    const openWishlist = async (event: MouseEvent | KeyboardEvent): Promise<void> => {
        event?.preventDefault();
        const clickElement =
            wishlist && wishlist.length > 0 ? 'Wishlist filled' : 'Wishlist unfilled';
        await window.yieldToMainThread();
        trackWishlistButtonClick(`${window.location.origin}/wishlist`, clickElement);
        window.location.assign('/wishlist');
    };

    useEffect(() => {
        if (wishlist) trackWishlistItemCount(wishlist.length);
    }, [wishlist]);

    return (
        <Button
            variant={ButtonVariant.Ghost}
            as={RenderType.Link}
            shape={ButtonShape.Icon}
            size={ButtonSize.Small}
            color={ButtonColor.Dark}
            href="/wishlist"
            className={classNames(layoutClassName, 'position-relative')}
            onClick={openWishlist}
            a11yText={intl.formatMessage(messages.description)}
            data-testid="wishlist-open-link"
            data-no-instant
            internal
        >
            <HeartIcon />
            {!!wishlist?.length && (
                <Badge
                    data-testid="wishlist-badge"
                    className={classNames('position-absolute', styles.badge)}
                    size={BadgeSize.Small}
                    color={BadgeColor.Complementary}
                    isRounded
                >
                    <span className="screenReaderOnly">
                        {intl.formatMessage(messages.itemsInWishlist)}
                    </span>
                    {wishlist.length}
                </Badge>
            )}
        </Button>
    );
};

export { WishlistButton };
