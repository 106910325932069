import { type MouseEvent } from 'react';
import classNames from 'classnames';

import { GA4EventName, GA4FeatureCategory } from '@jsmdg/tracking';
import { ChevronIcon, Link, LinkVariant } from '@jsmdg/yoshi';
import { type NavigationItem } from '../../../../shared/types';
import { getLinkWithLocationParams } from '../../../helper/getLinkWithLocationParams';
import { isInternalLink } from '../../../helper/isInternalLink';
import {
    availableTrackingActions,
    trackingCategories,
    trackNavigationClick,
} from '../../../helper/trackNavigation';
import { useHoverTracking } from '../../../hooks/useHoverTracking';
// eslint-disable-next-line import/no-cycle
import { DesktopNavigationGroup } from '../DesktopNavigationGroup/DesktopNavigationGroup';
import styles from './DesktopNavigationItem.module.scss';

function isNavigationItemLink(item: NavigationItem, depth: number, maxDepth: number): boolean {
    return Boolean(
        (item.uri && !item.children) ||
            (item.children && !item.children.length && depth < maxDepth),
    );
}

type DesktopNavigationItemProps = {
    readonly item: NavigationItem;
    readonly depth: number;
    readonly maxDepth: number;
    readonly isActive?: boolean;
    readonly isSelfReferrer?: boolean;
    readonly handleEnterItem?: () => void;
    readonly handleLeaveItem?: () => void;
    readonly trackingLabel?: string;
};

const DesktopNavigationItem = ({
    depth,
    handleEnterItem = () => {},
    handleLeaveItem = () => {},
    isActive = false,
    isSelfReferrer = false,
    // eslint-disable-next-line no-empty-function
    item,
    // eslint-disable-next-line no-empty-function
    maxDepth,
    trackingLabel,
}: DesktopNavigationItemProps): JSX.Element => {
    const { setIsFromTouchEvent, trackDesktopNavigationHoverIn } = useHoverTracking(
        depth,
        maxDepth,
    );

    const trackingData = {
        eventName: GA4EventName.OpenNavigationItem,
        feature_category: GA4FeatureCategory.TopNavigation,
        click_element: trackingLabel?.replace(/_/g, ' > '),
        click_text: item.title,
        navigationlevel: depth,
    };

    function handleMouseEnter(): void {
        handleEnterItem();
        trackDesktopNavigationHoverIn(item, trackingLabel, trackingData);
    }

    const handleMouseLeave = (): void => {
        handleLeaveItem();
    };

    const onLinkClick = (e: MouseEvent): void => {
        e.preventDefault();
        trackNavigationClick({
            e,
            category: trackingCategories.TOP_NAVI,
            action: availableTrackingActions.CLICK,
            eventData: {
                ...trackingData,
                eventName: GA4EventName.ClickNavigationItem,
                click_url: window.location.origin + item.uri,
            },
        });
        window.location.href = getLinkWithLocationParams(item.uri);
    };

    return (
        <li
            className={classNames('pr-1-5x pt-1x', styles.navigationItem, {
                [styles.open]: isActive,
            })}
            onTouchStart={() => setIsFromTouchEvent(true)}
            onMouseLeave={() => handleMouseLeave()}
            onMouseEnter={() => handleMouseEnter()}
            role="presentation"
        >
            {isNavigationItemLink(item, depth, maxDepth) ? (
                <Link
                    variant={LinkVariant.GreyQuiet}
                    href={item.uri}
                    className={classNames(
                        'pl-1x w-100 h-100 d-flex justify-content-between align-items-start',
                        styles.navigationItemTitle,
                        {
                            [styles.selfReferringItemLink]: isSelfReferrer,
                        },
                    )}
                    internal={isInternalLink(item.uri)}
                    onClick={onLinkClick}
                >
                    {item.title}
                </Link>
            ) : (
                <>
                    <Link
                        href={item.uri}
                        className={classNames(
                            'pl-1x w-100 h-100 d-flex justify-content-between align-items-start text-truncate',
                            styles.navigationItemTitle,
                        )}
                        onClick={onLinkClick}
                        internal={isInternalLink(item.uri)}
                        variant={LinkVariant.GreyQuiet}
                    >
                        {item.title}
                        {depth < maxDepth && <ChevronIcon className={styles.navigationItemArrow} />}
                    </Link>
                    {depth < maxDepth && (
                        <DesktopNavigationGroup
                            group={item}
                            isLastLevel
                            className={styles.navigationLastLevel}
                            depth={depth}
                            maxDepth={maxDepth}
                            parentTrackingLabel={trackingLabel}
                        />
                    )}
                </>
            )}
        </li>
    );
};

export { DesktopNavigationItem };
