import { type JSX } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { CheckMarkLockIcon } from '@jsmdg/yoshi';
import styles from './SecureCheckout.module.scss';

export const SecureCheckoutHint = (): JSX.Element => {
    return (
        <div
            data-testid="secure-checkout-hint"
            className={classNames(
                styles.secureCheckout,
                'd-inline-flex justify-content-between align-items-center',
            )}
        >
            <CheckMarkLockIcon />
            <FormattedMessage defaultMessage="Sichere Bezahlung">
                {msg => <span>{msg}</span>}
            </FormattedMessage>
        </div>
    );
};
