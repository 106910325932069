import {
    EventCategory,
    GA4EventName,
    GA4FeatureCategory,
    trackAnalyticsEvent,
} from '@jsmdg/tracking';
import { Link, LinkVariant } from '@jsmdg/yoshi';
import { isInternalLink } from '../../helper/isInternalLink';

type SimpleTextLinkProps = {
    readonly url: string;
    readonly label: string;
    readonly eventName?: string | null;
    readonly eventLabel?: string | null;
    readonly target?: string;
};

const SimpleTextLink = ({
    eventLabel = null,
    eventName = null,
    label,
    target = '_self',
    url,
}: SimpleTextLinkProps): JSX.Element => {
    const trackClick = async (): Promise<void> => {
        if (eventName === null || eventLabel === null) {
            return;
        }

        await window.yieldToMainThread();

        trackAnalyticsEvent({
            category: EventCategory.Navigation,
            action: 'Header',
            label: eventLabel,
            eventData: {
                eventName: GA4EventName.ClickNavigationItem,
                feature_category: GA4FeatureCategory.HeaderNavigation,
                click_element: eventName,
                click_text: label,
                click_url: eventName === 'Contact' ? `${window.location.origin}${url}` : url,
            },
        });
    };

    return (
        <Link
            href={url}
            className="mr-3x text-secondary"
            variant={LinkVariant.GreyQuiet}
            onClick={trackClick}
            target={target}
            internal={isInternalLink(url)}
        >
            <span>{label}</span>
        </Link>
    );
};

export { SimpleTextLink };
