import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';

import { trackCartCheckoutStep } from '@jsmdg/tracking';
import { CheckIcon, PureLink } from '@jsmdg/yoshi';
import styles from './CheckoutStep.module.scss';

const messages: Record<string, { defaultMessage: string }> = defineMessages({
    stepOne: {
        defaultMessage: 'Warenkorb',
    },
    stepTwo: {
        defaultMessage: 'Bestelldaten',
    },
    stepThree: {
        defaultMessage: 'Letzter Check',
    },
});

type CheckoutStepProps = {
    step: number;
    activeStep: number;
    titleId: string;
    stepUrl?: string;
};

const CheckoutStep = ({ activeStep, step, stepUrl, titleId }: CheckoutStepProps): JSX.Element => {
    const intl = useIntl();
    const stepClassNames =
        'd-flex justify-content-end justify-content-md-start align-items-center flex-column flex-md-row pb-1x pl-md-4x';

    const onCheckOutClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
        event.preventDefault();
        if (stepUrl) {
            trackCartCheckoutStep(
                step,
                `${window.location.origin}${stepUrl}`,
                intl.formatMessage(messages[titleId]),
            );
            window.location.href = stepUrl;
        }
    };

    const renderStep = useMemo(() => {
        const stepInnerMarkup = (
            <>
                <span className="order-2 order-md-1 ml-0-5x mr-md-0-5x">
                    {intl.formatMessage(messages[titleId])}
                </span>
                <span className={classNames('order-0 mb-0-5x mb-md-0 pr-md-1x', styles.stepNumber)}>
                    {step}
                </span>
                <CheckIcon className="order-1 order-md-2" />
            </>
        );

        if (step < activeStep) {
            return (
                <PureLink
                    href={stepUrl}
                    onClick={e => {
                        onCheckOutClick(e);
                    }}
                    className={classNames(stepClassNames, styles.step, {
                        [styles.active]: step === activeStep,
                        [styles.checked]: step < activeStep,
                    })}
                    internal
                >
                    {stepInnerMarkup}
                </PureLink>
            );
        }

        return (
            <div
                className={classNames(stepClassNames, styles.step, {
                    [styles.active]: step === activeStep,
                    [styles.checked]: step < activeStep,
                })}
            >
                {stepInnerMarkup}
            </div>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStep, intl, step, stepUrl, titleId]);

    return renderStep;
};

export { CheckoutStep };
