import { type MouseEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import {
    ClickEventLabel,
    EventAction,
    EventCategory,
    GA4EventName,
    GA4FeatureCategory,
    trackAnalyticsEvent,
} from '@jsmdg/tracking';
import { ButtonColor, Link, LinkVariant, TicketsOutlineThickIcon } from '@jsmdg/yoshi';
import styles from './RedeemButton.module.scss';

const RedeemButtonDefault = ({
    layoutClassName,
    redemptionUrl,
}: {
    readonly redemptionUrl: string;
    readonly layoutClassName?: string;
}): JSX.Element => {
    const onclick = async (e: MouseEvent): Promise<void> => {
        e.preventDefault();

        await window.yieldToMainThread();

        trackAnalyticsEvent({
            category: EventCategory.Header,
            action: EventAction.Click,
            label: ClickEventLabel.REDEEM_YOUR_VOUCHER,
            nonInteraction: false,
            eventData: {
                eventName: GA4EventName.ClickNavigationItem,
                feature_category: GA4FeatureCategory.HeaderNavigation,
                click_element: 'Redeem Voucher',
                click_url: redemptionUrl,
            },
        });

        window.location.assign(redemptionUrl);
    };

    return (
        <Link
            variant={LinkVariant.Brand}
            color={ButtonColor.Brand}
            className={classNames('fw-semibold py-1-5x', styles.link, layoutClassName)}
            href={redemptionUrl}
            iconLeft={<TicketsOutlineThickIcon />}
            onClick={onclick}
            internal
        >
            <FormattedMessage defaultMessage="Gutschein einlösen" />
        </Link>
    );
};

export { RedeemButtonDefault };
